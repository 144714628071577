<template>
    <div class="" style="height:100%">
        <Header />
    <div class="list">

        <div class="buttons">
            <div>
                <el-select v-model="selectPlan" size="mini" placeholder="请选择方案">
                    <el-option value="全部">全部</el-option>
                    <el-option v-for="(item,index) in options" :key="index" :label="item.label" :value="index">
                    </el-option>
                </el-select>
                <!-- 记录数：{{datacount}} 选中记录：{{checkcount}} -->
            </div>
            <div>
                <el-button size="mini" round v-if="selectPlan == '全部'" @click="dialogVisible = true" type="success">保存方案</el-button>
                <el-button size="mini" round v-else @click="dialogVisible = true" type="primary">更新方案</el-button>
                <el-button size="mini" round v-if="selectPlan != '全部'" @click="open" type="warning">删除方案</el-button>
                <el-button size="mini" round @click="exports()">导出EXCEL</el-button>
            </div>
        </div>
        <div class="rows">
            <div class="container" ref="form">
                <table border="0" id="table">
                    <thead>
                        <tr>
                            <th v-for="(item, index) in columns" :key="index" @click="(currentSelect = ''), sorts(item.key)">
                                <div class="box" :style="'width:'+item.width">
                                    <label class="lables" v-if="index==0"><input type="checkbox" :checked="contentAll" @click.stop="handleCheckAllChange1"></label>
                                    <div class="lable">{{ item.title }} <br> {{ item.subtitle }}</div>
                                    <div class="flex">
                                        <i class="el-icon-caret-top" :class="{ active: currentSort == item.key && currentSortStatus == 'up', }"></i>
                                        <i class="el-icon-caret-bottom" :class="{ active: currentSort == item.key && currentSortStatus == 'down', }"></i>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="search">
                            <td v-for="(item, index) in columns" :key="index">
                                <div :style="'width:'+item.width+'px'" class="td">
                                    <div @click.stop="currentSelect = currentSelect == item.key ? '' : item.key" class="searchTitle">
                                        <div>
                                            <span v-show="label[item.key]" v-for="(v, i) in label[item.key]" :key="i">
                                                {{ v }}<template v-if="label[item.key].length > i + 1">,</template>
                                            </span>
                                        </div>
                                        <i class="el-icon-caret-bottom"></i>
                                    </div>
                                    <div class="selects" :class="{ active: currentSelect == item.key }">
                                        <input type="text" v-model="item.search" placeholder="快捷检索" class="searchQuick">
                                        <label class="options"><input type="checkbox" @click="searchs(item, '全选')" />全选</label>
                                        <label :for="v" class="options" v-for="(v, i) in item.childs" :key="i" v-show="!item.search||(v+'').indexOf(item.search)>=0">
                                            <input type="checkbox" :checked="label[item.key] && label[item.key].indexOf(v) >= 0 " @change="searchs(item, v)" :name="v" :id="v" /> {{ v }}
                                        </label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="hover" v-for="(item, index) in data" :key="index" v-show="checkShow(item)" @click="currentSelect = ''">
                            <td>
                                <label style="cursor: pointer;"><input type="checkbox" :checked="contentArr.indexOf(item.ID) >= 0" @change="handleCheckAllChange(item.ID)">{{item.SSDS}}</label>
                            </td>
                            <td>{{ item.SSX }}</td>
                            <td>{{ item.XMMC }}</td>
                            <td>{{ item.LXBH }}</td>
                            <td>{{ item.XMXZ }}</td>
                            <td>{{ item.JSXZ }}</td>
                            <td>{{ item.XMLX }}</td>
                            <td>{{ item.GLLX }}</td>
                            <td>{{ item.KGN }}</td>
                            <td>{{ item.WGN }}</td>
                            <td>{{ item.JSGMGS }}</td>
                            <td>{{ item.JSGMYJ }}</td>
                            <td>{{ item.JSGMEJ }}</td>
                            <td>{{ item.JSGMSJ }}</td>
                            <td>{{ item.JSGMQS }}</td>
                            <td>{{ item.ZTZ }}</td>
                            <td>{{ item.ZYCGS }}</td>
                            <td>{{ item.SAWZTJ }}</td>
                            <td>{{ item.SAWZYCGS }}</td>
                            <td>{{ item.SSWZTZ }}</td>
                            <td>{{ item.SSWZYCGS }}</td>
                            <td>{{ item.SSWSJZJ }}</td>
                            <td>{{ item.SSWSXZCTZ }}</td>
                            <td>{{ item.SWWZTZ }}</td>
                            <td>{{ item.SWWZYCGS }}</td>
                            <td>{{ item.SFYDSXW }}</td>
                            <td>{{ item.XMLY }}</td>
                            <td>{{ item.QQGZJZ }}</td>
                            <td>{{ item.BZ }}</td>
                        </tr>
                    </tbody>
                </table>
                <div style="display: none">{{ labels }}</div>
            </div>
        </div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
            <el-form ref="form">
                <el-form-item label="请填写方案名称" v-if="selectPlan == '全部'">
                    <el-input v-model="namePlan" placeholder="请填写"></el-input>
                </el-form-item>
                <el-form-item label="请确认方案名称" v-else>
                    <el-input v-model="namePlan" placeholder="请填写"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
    </div>
</template>

<script>
// import { exportData } from "../../js/util/export.js"; //导出表格
import Header from "@/views/Home/Header.vue";

export default {
    name: "list",
    components: {Header},
    data() {
        return {
            fieldList:[],
            datacount: 0, //总记录数 暂时未启用
            dialogVisible: false, //方案名称弹框
            selectPlan: "全部", //选中的方案
            namePlan: "", //更新/新增名称
            indexPlan: "", //选中的方案索引
            options: [], //方案数据
            tableData: null, //未启用
            columns: [
                //table表头数据
                {
                    title: "所在地市",
                    subtitle: "",
                    key: "SSDS",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "所属县(市)",
                    subtitle: "",
                    key: "SSX",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "项目名称",
                    subtitle: "",
                    key: "XMMC",
                    childs: [],
                    check: false,
                    width: 200,
                    search: "",
                },
                {
                    title: "路线编号",
                    subtitle: "",
                    key: "LXBH",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "项目性质",
                    subtitle: "",
                    key: "XMXZ",
                    childs: [],
                    check: false,
                    width: 150,
                    search: "",
                },
                {
                    title: "建设性质",
                    subtitle: "",
                    key: "JSXZ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "项目类型",
                    subtitle: "",
                    key: "XMLX",
                    childs: [],
                    check: false,
                    width: 140,
                    search: "",
                },
                {
                    title: "建设类别",
                    subtitle: "",
                    key: "GLLX",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "开工年",
                    subtitle: "",
                    key: "KGN",
                    childs: [],
                    check: false,
                    width: 90,
                    search: "",
                },
                {
                    title: "完工年",
                    subtitle: "",
                    key: "WGN",
                    childs: [],
                    check: false,
                    width: 90,
                    search: "",
                },
                {
                    title: "建设规模",
                    subtitle: "高速(公里)",
                    key: "JSGMGS",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "建设规模",
                    subtitle: "一级(公里)",
                    key: "JSGMYJ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "建设规模",
                    subtitle: "二级(公里)",
                    key: "JSGMEJ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "建设规模",
                    subtitle: "三级(公里)",
                    key: "JSGMSJ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "建设规模",
                    subtitle: "桥隧(数量)",
                    key: "JSGMQS",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "总投资",
                    subtitle: "",
                    key: "ZTZ",
                    childs: [],
                    check: false,
                    width: 90,
                    search: "",
                },
                {
                    title: "中央车购税",
                    subtitle: "",
                    key: "ZYCGS",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "“十三五”",
                    subtitle: "投资(亿元)",
                    key: "SAWZTJ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "“十三五”中央",
                    subtitle: "车购税(亿元)",
                    key: "SAWZYCGS",
                    childs: [],
                    check: false,
                    width: 120,
                    search: "",
                },
                {
                    title: "“十四五”投资",
                    subtitle: "",
                    key: "SSWZTZ",
                    childs: [],
                    check: false,
                    width: 120,
                    search: "",
                },
                {
                    title: "“十四五”",
                    subtitle: "中央车购税",
                    key: "SSWZYCGS",
                    childs: [],
                    check: false,
                    width: 140,
                    search: "",
                },
                {
                    title: "“十四五”",
                    subtitle: "省级资金",
                    key: "SSWSJZJ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "“十四五”市县",
                    subtitle: "征拆投资",
                    key: "SSWSXZCTZ",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "“十五五”投资",
                    subtitle: "",
                    key: "SWWZTZ",
                    childs: [],
                    check: false,
                    width: 120,
                    search: "",
                },
                {
                    title: "“十五五”",
                    subtitle: "中央车购税",
                    key: "SWWZYCGS",
                    childs: [],
                    check: false,
                    width: 100,
                    search: "",
                },
                {
                    title: "是否地市行文",
                    subtitle: "",
                    key: "SFYDSXW",
                    childs: [],
                    check: false,
                    width: 140,
                    search: "",
                },
                {
                    title: "项目来源",
                    subtitle: "",
                    key: "XMLY",
                    childs: [],
                    check: false,
                    width: 150,
                    search: "",
                },
                {
                    title: "前期工作进展",
                    subtitle: "",
                    key: "QQGZJZ",
                    childs: [],
                    check: false,
                    width: 120,
                    search: "",
                },
                {
                    title: "备注",
                    subtitle: "",
                    key: "BZ",
                    childs: [],
                    check: false,
                    width: 200,
                    search: "",
                },
            ],
            sortArr: [
                //数字排序字段
                "KGN",
                "WGN",
                "JSGMGS",
                "JSGMYJ",
                "JSGMEJ",
                "JSGMSJ",
                "JSGMQS",
                "ZTZ",
                "ZYCGS",
                "SAWZTJ",
                "SAWZYCGS",
                "SSWZTZ",
                "SSWZYCGS",
                "SSWSJZJ",
                "SSWSXZCTZ",
                "SWWZTZ",
                "SWWZYCGS",
            ],
            data: [], //表格数据
            olddata: [], //原始数据
            label: {}, //过滤条件
            labels: [], //临时过滤条件，触发数据变化监听
            currentSort: "", //当前排序
            currentSortStatus: "none", //当前排序状态
            currentSelect: "", //选中的排序
            exportArr: [], //导出用的ID
            contentArr: [], //选中的数据ID
            contentAll: false, //是否全选
            getSearch: {
                //分页参数/导出参数
                page: 1,
                rows: 1000,
                Sort: "DSBM",
                Order: "asc",
            },
        };
    },
    mounted() {
        this.getData();
        this.getPlan();

        this.columns.map(r=>{
            return this.fieldList.push({
                title:r.title,
                vis:true
            })
        })
        console.log(this.fieldList);
        
    },
    watch: {
        selectPlan(e) {
            //监听切换方案事件
            this.switchPlan(e);
        },
    },
    methods: {
        export2Excel() {
            let date = new Date();
            var that = this;
            require.ensure([], () => {
                const {
                    export_json_to_excel,
                } = require("../../excel/Export2Excel"); //这里必须使用绝对路径，根据自己的命名和路径
                var tHeader = []; // 导出的表头名
                var filterVal = []; // 导出的表头字段名
                var tabledata = [];
                that.columns.forEach((res) => {
                    tHeader.push(res.title + res.subtitle);
                    filterVal.push(res.key);
                });
                that.contentArr.forEach((res) => {
                    var find = this.olddata.find((item) => item.ID == res);
                    tabledata.push(find);
                });
                // 为传入的数据
                var data = that.formatJson(filterVal, tabledata);
                export_json_to_excel(
                    tHeader,
                    data,
                    that.namePlan != "全部"
                        ? that.options[that.indexPlan].label
                        : date.getMonth() + 1 + "月" + date.getDay() + "日"
                ); // 导出的表格名称，根据需要自己命名
                // tHeader为导出Excel表头名称，`xxxxxx`即为导出Excel名称
            });
        },
        formatJson(filterVal, jsonData) {
            return jsonData.map((v) => filterVal.map((j) => v[j]));
        },
        handleCheckAllChange1() {
            //全选事件
            this.contentAll = !this.contentAll;
            this.contentArr = [];
            if (this.contentAll) {
                this.data.forEach((item) => {
                    let status = 0;
                    this.columns.forEach((v) => {
                        if (
                            this.label[v.key].length > 0 &&
                            this.label[v.key].indexOf(item[v.key]) < 0
                        ) {
                            status = 1;
                        }
                    });
                    if (status == 0) {
                        this.contentArr.push(item.ID);
                    }
                });
            }
        },
        handleCheckAllChange(val) {
            //复选框选中事件
            if (this.contentArr.indexOf(val) >= 0) {
                this.contentArr.splice(this.contentArr.indexOf(val), 1);
            } else {
                this.contentArr.push(val);
            }
        },
        exports() {
            //导出数据
            var that = this;
            this.$confirm("此操作将导出excel文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    if (that.contentArr.length <= 0) {
                        that.$message.error("没有需要导出的数据");
                        return false;
                    }
                    that.export2Excel();
                })
                .catch(() => {});
            // console.log(this.exportArr);
            // exportData("Plan_road", this.getSearch);
        },
        switchPlan(e) {
            //切换方案
            let plan = 0; //0按选中ID重新读取 1按过滤条件读取
            if (plan == 0) {
                this.currentSort = "";
                this.currentSortStatus = "none";
                this.currentSelect = "";
                this.contentArr = [];
                this.contentAll = false;
                this.namePlan = "";
                this.indexPlan = "";
                //还原过滤
                this.columns.forEach((res) => {
                    this.label[res.key] = [];
                });
                // 还原排序
                this.reorder();
                if (e == "全部") {
                    this.data = this.olddata;
                } else {
                    this.namePlan = this.options[e].label;
                    this.indexPlan = e;
                    this.data = [];
                    this.contentArr = this.options[e].content.split(",");
                    this.contentArr.forEach((res) => {
                        //先读取选中顺序
                        let find = this.olddata.find((item) => item.ID == res);
                        this.data.push(find);
                    });
                    this.olddata.forEach((res) => {
                        //默认读取剩余部分
                        if (this.contentArr.indexOf(res.ID) < 0) {
                            this.data.push(res);
                        }
                    });
                }
            } else {
                //带过滤条件
                if (e != "全部") {
                    this.namePlan = this.options[e].label;
                    this.indexPlan = e;
                    this.currentSort = this.options[e].sort;
                    this.currentSortStatus = this.options[e].srotstatus;
                    this.currentSelect = "";
                    this.columns.forEach((res) => {
                        this.label[res.key] = [];
                        this.options[e].filters[res.key].forEach((v) => {
                            this.label[res.key].push(v);
                        });
                    });
                    this.reorder();
                } else {
                    this.namePlan = "";
                    this.indexPlan = "";
                    //还原过滤
                    this.columns.forEach((res) => {
                        this.label[res.key] = [];
                    });
                    // 还原排序
                    this.reorder();
                }
            }

            this.counts();
        },
        open() {
            //删除提示
            this.$confirm("此操作将永久删除该方案, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.http
                        .post(
                            "/api/Proj_draft/DeleteProj",
                            { id: this.options[this.indexPlan].id },
                            ""
                        )
                        .then((res) => {
                            this.$message.success(res.message);
                            this.selectPlan = "全部";
                            this.switchPlan("全部");
                        });
                    // this.options.splice(this.indexPlan, 1);
                })
                .catch(() => {});
        },
        submit() {
            //保存/更新 方案
            if (!this.namePlan) {
                this.$message.error("请输入方案名称");
                return;
            }
            if (this.contentArr.length <= 0) {
                this.$message.error("请先选择要保存的条目");
                this.dialogVisible = false;
                return;
            }
            //储存过滤后的数据 暂时弃用
            // const filters = {};
            // this.columns.forEach((r) => {//重新赋值当前过滤条件 防止数据索引错乱
            //     let d = [];
            //     if (this.label[r.key].length > 0) {
            //         this.label[r.key].forEach((rrr) => {
            //             d.push(rrr);
            //         });
            //     }
            //     filters[r.key] = d;
            // });

            const formdata = {
                label: this.namePlan,
                id: 0, //this.options.length + 1
                filters: "", //filters
                sort: this.currentSort,
                srotstatus: this.currentSortStatus,
                content: this.contentArr.join(","),
                userid: 0,
            };
            // console.log(formdata);
            let url = "";
            if (this.selectPlan != "全部") {
                //更新方案
                // this.options[this.indexPlan] = formdata;
                formdata.id = this.options[this.indexPlan]["id"];
                formdata.userid = this.options[this.indexPlan]["userid"];
                // console.log(formdata);
                url = "/api/Proj_draft/UpdateProj";
            } else {
                //新增方案
                // this.options.push(formdata);
                url = "/api/Proj_draft/AddProj";
            }
            // return;
            this.http.post(url, formdata, "请稍候...").then((res) => {
                this.$message.success(res.message);
                this.getPlan();

                this.selectPlan = "全部";
                this.namePlan = "";
                this.indexPlan = "";
                this.dialogVisible = false;
                this.contentArr = [];
            });
        },
        columnsWidth(name) {
            //设置表格td宽度
            const arr = this.columns.find((item) => item.key == name);
            return arr ? arr.width : 100;
        },
        filters() {
            //初始化过滤条件，数据中增加后期使用字段
            this.data.forEach((v, i) => {
                v.sort = i; //初始化排序，还原排序使用
                v.check = false; //复选框预设
                this.columns.forEach((vv) => {
                    if (this.sortArr.indexOf(vv.key) < 0 && !v[vv.key])
                        v[vv.key] = " "; //空数据初始化防止汉字排序报错
                });
                this.columns.forEach((vv) => {
                    vv.childs.push(v[vv.key]);
                    vv.childs = [...new Set(vv.childs)]; //过滤条件去重
                    if (!this.label[vv.key]) this.label[vv.key] = []; //初始化过滤条件选中数组
                });
            });
            this.columns.forEach((res) => {
                if (this.sortArr.indexOf(res.key) >= 0) {
                    res.childs.sort();
                }
            });
            this.olddata = this.data; //原始数据存根，还原方案使用
        },
        reorder() {
            //排序
            let cst = this.currentSort;
            if (this.currentSort == "SSDS") {
                //针对地市排序使用地市编码
                cst = "DSBM";
            }
            if (this.currentSort == "SSX") {
                //针对区县排序使用区县编码
                cst = "QXBM";
            }
            if (this.currentSortStatus == "none") {
                //还原排序 默认初始排序
                return this.data.sort((p1, p2) => {
                    return p1.sort - p2.sort;
                });
            }
            if (this.sortArr.indexOf(this.currentSort) >= 0) {
                //数字排序
                return this.data.sort((p1, p2) => {
                    if (this.currentSortStatus == "up") {
                        return p1[cst] - p2[cst];
                    } else {
                        return p2[cst] - p1[cst];
                    }
                });
            } else {
                return this.data.sort((x, y) => {
                    //中文字母排序
                    if (this.currentSortStatus == "up") {
                        return x[cst].localeCompare(y[cst]);
                    } else {
                        return y[cst].localeCompare(x[cst]);
                    }
                });
            }
        },
        getData() {
            //请求数据源
            this.http
                .post(
                    "/api/Plan_road/GetPageData",
                    this.getSearch,
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        this.total = res.total;
                        this.data = res.rows;
                        this.counts();
                        this.filters();
                    }
                });
        },
        getPlan() {
            this.http
                .post(
                    "/api/Proj_draft/GetPageData",
                    {},
                    "正在请求数据，请稍候..."
                )
                .then((res) => {
                    if (res.status == 0) {
                        // console.log(res);
                        this.options = res.rows;
                    }
                });
        },
        checkShow(item) {
            //渲染数据过程中过滤数据
            let status = 0;
            this.columns.forEach((v) => {
                if (
                    this.label[v.key] &&
                    this.label[v.key].length > 0 &&
                    this.label[v.key].indexOf(item[v.key]) < 0
                ) {
                    status = 1;
                }
            });
            if (status == 1) {
                item.is = 1;
                return false;
            }
            item.is = 0;
            return true;
        },
        counts() {
            //获取选中的数据ID
            // let count = this.data.length;
            this.exportArr = [];
            this.data.forEach((item) => {
                let status = 0;
                this.columns.forEach((v) => {
                    if (
                        this.label[v.key] &&
                        this.label[v.key].length > 0 &&
                        this.label[v.key].indexOf(item[v.key]) < 0
                    ) {
                        status = 1;
                    }
                });
                if (status == 1) {
                    // count -= 1;
                } else {
                    this.exportArr.push(item.ID);
                }
            });
            // this.datacount = count;
        },
        // reduce(arr) {
        //     const res = new Map();
        //     return arr.filter(
        //         item => !res.has(item._id) && res.set(item._id, 1)
        //     );
        // },
        searchs(item, v) {
            //过滤数据选项
            this.labels = [];
            if (v == "全选") {
                //单项全选
                this.columns.forEach((vi) => {
                    if (vi.key == item.key) {
                        if (vi.check) {
                            this.label[item.key] = [];
                        } else {
                            vi.childs.forEach((vv) => {
                                this.label[item.key].push(vv);
                            });
                        }
                        vi.check = !vi.check;
                    }
                });
                this.datacount = this.data.length;
            } else {
                //单点过滤
                if (this.label[item.key].indexOf(v) >= 0) {
                    //已在过滤条件中则取出
                    this.label[item.key].splice(
                        this.label[item.key].indexOf(v),
                        1
                    );
                } else {
                    this.label[item.key].push(v); //不在过滤条件中新增
                }
                this.counts();
            }
            this.labels.push(v);
        },
        sorts(name) {
            //设置排序状态
            if (name == this.currentSort) {
                if (this.currentSortStatus == "none") {
                    this.currentSortStatus = "up";
                } else if (this.currentSortStatus == "up") {
                    this.currentSortStatus = "down";
                } else {
                    this.currentSortStatus = "none";
                }
            } else {
                this.currentSortStatus = "up";
            }
            this.currentSort = name;
            this.reorder(); //调用排序
        },
    },
};
</script>
<style scoped>
/* body {
    box-sizing: border-box;
} */
</style>
<style>
body > div:first-child {
    height: 100%;
}
body > div:first-child > div {
    height: 100%;
}
.el-loading-spinner,
.el-message {
    height: auto !important;
}
</style>
<style lang="less" scoped>
.hover {
    &:hover {
        td {
            background: #effaff !important;
        }
    }
}
.searchQuick {
    width: calc(100% - 30px);
    margin-left: 10px;
    border-radius: 5px;
    height: 25px;
    border: 1px #eee solid;
    padding: 0 5px;
    font-size: 12px;
}
.lables {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.list {
    position: relative;
    background: #f4f6f7;
    padding: 20px;
    height: calc(100% - 130px);
    box-sizing: border-box;
    .buttons {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding-bottom: 20px;
    }
    .rows {
        height: 100%;
        padding: 15px;
        background: #fff;
        border-radius: 5px;
        .container {
            height: 100%;
            overflow: auto;
            border: 1px #e8eaec solid;
            border-radius: 5px;
        }
    }
}
#table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
    td,
    th {
        border-right: 1px solid #e8eaec;
        width: 10px;
    }
    th {
        min-width: 0;
        box-sizing: border-box;
        text-align: left;
        border-bottom: 1px solid #e8eaec;
        height: 40px;
        padding: 5px 0;
        white-space: nowrap;
        overflow: hidden;
        background-color: #f8f8f9;
        cursor: pointer;
        user-select: none;
        .lable {
            margin-right: 5px;
        }
        .box {
            display: flex;
            // justify-content: center;
            align-items: center;
            background: #f8f8f9;
            box-shadow: 0 0 0;
            padding: 0 5px;
        }
        .flex {
            flex-direction: row;
            display: flex;
            flex-flow: wrap;
            i {
                margin-left: 5px;
                position: relative;
                top: -2px;
                width: 100%;
                height: 8px;
                color: #ddd;
            }
            .active {
                color: #09cf5e;
            }
        }
    }
    td {
        min-width: 0;
        height: 38px;
        padding: 5px 10px;
        box-sizing: border-box;
        text-align: left;
        text-overflow: ellipsis;
        vertical-align: middle;
        border-bottom: 1px solid #e8eaec;
        background-color: #fff;
        transition: background-color 0.2s ease-in-out;
        label {
            display: flex;
            align-items: center;
            input {
                margin-right: 5px;
            }
        }
    }
    .search {
        td {
            padding: 0 0 0 0;
            height: 25px;
            cursor: pointer;
            user-select: none;
            position: relative;
            background: #f5f5f5;
            .searchTitle {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 10px;
                white-space: nowrap;
                height: 100%;
                overflow: hidden;
                div {
                    overflow: auto;
                    width: 100%;
                    &::-webkit-scrollbar {
                        height: 2px;
                        width: 6px;
                        z-index: 9999;
                    }
                }
            }
            i {
                font-size: 18px;
            }
            .selects {
                height: 0;
                overflow: hidden;
                transition: 0.5s ease;
                background: #f5f5f5;
                width: 100%;
                position: absolute;
                text-align: left;

                .options {
                    padding: 5px 10px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    cursor: pointer;
                    input {
                        cursor: pointer;
                        margin-right: 5px;
                    }
                }
            }
            .active {
                // display: block;
                height: 200px;
                overflow: auto;
            }
        }
    }
}
</style>
